<template>
  <div class="index container">
    <transition name="fade">
      <router-view @showParent="showParentPage"> </router-view>
    </transition>
    <div v-if="showParentPage">
        List of QR code types here....
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return { showParentPage: false };
  },
  methods: {
    showParent(show) {
      this.showParentPage = show;
    },
  },
};
</script>

<style></style>
